import api from '@/services/api/api';

export const useMyHistory = () => {
  const myHistory = useInfiniteQuery(
    ['myHistory'],
    async () => await api.articles.getMyHistory(),
    {
      staleTime: Infinity,
      select: ({ pages }) => pages.flatMap(({ data }) => data?.data),
      refetchOnMount: true,
    },
  );

  myHistory.suspense();

  return {
    myHistory,
  };
};
